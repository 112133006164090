<script>
import emailjs from "emailjs-com";

export default {
  name: "Presupuesto",
  data() {
    return {
      values: null,
      value: "",
    };
  },
  methods: {
    sendEmail() {
      emailjs
          .send(
              "service_7lsnt28",
              "template_0s26ht5",
              this.values,
              "fC0xER6Vz9r3UoRfJ"
          )
          .then((result) => {
            this.$toastr.success(
                "Su pedido de presupuesto fue enviado, estaremos en contacto",
                "Mensaje enviado"
            );
            console.log("Exito", result.status);
            this.values = null;
          })
          .catch((error) => {
            this.$toastr.error(error, "Mensaje enviado");
            console.error(error);
          });
    },
  },
}
</script>

<template>
  <div class="grid grid-cols-1 bg-white ">
    <div
        class="flex flex-row flex-wrap w-2/3 gap-8 pt-24 mx-auto mt-24 md:gap-32 lg:pt-32"
    >
    <div class="w-full lg:w-1/2 mx-auto text-left ">
        <FormulateForm v-model="values" @submit="sendEmail">
          <FormulateInput
              type="text"
              name="nombre"
              label="cuál es tu nombre y apellido?*"
              label-class="uppercase"
              placeholder="ej: Carlos Gutierrez"
              validation="required"
              :validation-messages="{
              required: 'El Nombre es requerido',
            }"
          />


          <FormulateInput
              :options="{menos24: 'menos de 24 años', e25y38: 'entre 25 y 38 años', e39y50: 'entre 39 y 50 años', mas51: 'más de 51 años' }"
              type="select"
              name="edad"
              label="¿Cuál es tu edad?*"
              label-class="uppercase"
              validation="required"
              :validation-messages="{
              required: 'La edad es requerido',
              }"
          />



          <FormulateInput
              type="email"
              name="email"
              label="¿A qué e-mail podemos responderte?*"
              label-class="uppercase"
              placeholder="ej: carlos@ejemplo.com"
              validation="required"
              :validation-messages="{
              required: 'El E-Mail es requerido',
            }"
          />

          <FormulateInput
              type="tel"
              name="telefono"
              label="¿A qué teléfono podemos llamarte?*"
              label-class="uppercase"
              placeholder="ej: +54 11 5637-5959"
          />

          <FormulateInput
              type="text"
              name="provincia"
              label="¿En qué Localidad y Provincia querés construir?*"
              label-class="uppercase"
              placeholder="ej: Mar del Plata - Buenos Aires"
              validation="required"
              :validation-messages="{
              required: 'La localidad y provincia es requerido',
            }"
          />

          <FormulateInput
              type="text"
              name="zona"
              label="¿Cuál es tu zona para ofrecerte nuestro servicio?*"
              placeholder="ej: zona centro"
              label-class="uppercase"
              validation="required"
              :validation-messages="{
              required: 'La zona es requerido',
            }"
          />

          <FormulateInput
              :options="{abierto: 'Abierto', cerrado: 'Cerrado', semicerrado: 'Semicerrado con seguridad'}"
              type="select"
              name="barrio"
              label="¿En qué tipo de Barrio querés construir?*"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{si: 'Si', no: 'No', analizando: 'Estoy analizando alternativas'}"
              type="select"
              name="lote"
              label="¿Ya contás con Lote?*"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{setenta: '70m2', alcanzar: 'hasta alcanzar 250m2'}"
              type="select"
              name="metros"
              label="¿Cuáles son los m2 totales que querés construir?*"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="dormitorios"
              label="¿Qué cantidad de dormitorios necesitas?*"
              placeholder="ej: 3 o 4 dormitorios"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="suite"
              label="¿Qué cantidad de dormitorios en suite necesitas?*"
              placeholder="ej: necesito 1 dormitorio en suite"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="vestidor"
              label="¿Qué cantidad de vestidores necesitas?*"
              placeholder="ej: necesito 1 vestidor"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="banios"
              label="¿Qué cantidad de baños completos totales necesitas?*"
              placeholder="ej: 2 baños completos"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="toilette"
              label="¿Qué cantidad de toilette necesitas?*"
              placeholder="ej: con uno esta bien"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{integrada: 'Integrada al living comedor', isla: 'Integrada al living comedor y con isla', soloCocina: 'separada y solo cocina', comedorDiario: 'separada y con comedor diario'}"
              type="select"
              name="cocina"
              label="¿Cómo te imaginas la cocina?*"
              label-class="uppercase"
          />

          <FormulateInput
              type="text"
              name="cochera"
              label="¿Qué cantidad de cocheras necesitas?*"
              placeholder="ej: con uno esta bien"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{cubierta: 'cubierta y solo cochera', semicubierta: 'semicubierta y solo cochera', cubiertaQuincho: 'cubierta con posibilidad de ser quincho', semiCubiertaQuincho: 'semi cubierta con posibilidad de ser quincho',
              descubierta: 'descubierta'}"
              type="select"
              name="imacochera"
              label="¿Cómo te imaginas esta cochera?*"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{galeriasi: 'Si', galeriano: 'No'}"
              type="select"
              name="galeria"
              label="¿Crees que es necesaria una galeria cubierta?*"
              label-class="uppercase"
          />

          <FormulateInput
              :options="{quincem: '15m2', hasta39: 'hasta alcanzar 39m2'}"
              type="select"
              name="galeriametros"
              label="¿Cuáles son los m2 totales que le destinarias a esta galeria?*"
              label-class="uppercase"
          />

          <FormulateInput
              type="textarea"
              v-model="value"
              name="mensaje"
              label="Utilizá este espacio para contarnos un poco más acerca de tu proyecto..."
              label-class="uppercase"
              validation="max:250,length"
              validation-name="tweet"
              :help="`Solo quedan ${250 - value.length} caractéres. `"
              :validation-messages="{
              max: 'El Mensaje debe tener menos de 250 caracteres',
            }"
          />

          <div class="w-full text-center">
            <p>(*) La respuesta es necesaria</p>
            <FormulateInput
                type="submit"
                name="enviar"
                label="Por favor, podrian enviarme un presupuesto estimado"
                input-class="w-full lg:w-2/3 p-2 lg:py-2 font-bold text-white capitalize bg-green-400 rounded"
            />
          </div>

        </FormulateForm>
      </div>
    </div>
  </div>
</template>
